import React from 'react';
import Button from '../components/Button';
import Layout from '../components/layout/Layout';


const Seller = () => (
    <Layout>
  
      <section className="pt-20 md:pt-40 SellerBack">
        <div className="mx-auto px-4">
            <h1 className="text-6xl font-bold leading-none text-white pb-40 drop-shadow-sm">
               Ready To Sell?<br/>
               Here's Our Checklist
            </h1>
        </div>
      </section>
  
      <section className="pt-5 mx-5">
        <div className="container mx-auto pt-5 pb-5 mx-auto bg-gray-200 rounded-lg">
            <h1 className="text-center text-2xl lg:text-3xl font-regular leading-none">
                Things You Must Know About<br/>Selling Real Estate
            </h1>        
          <div className="pt-10 px-10 sm:-mx-3">
            <h2 className="text-2xl lg:text-2xl font-regular text-primary-darker">Fact #1</h2>
            <p className="text-grey-900">
            Did you know you can lose money and time by over pricing your home?
            </p>
          </div>
          <div className="pt-10 px-10 sm:-mx-3">
            <h2 className="text-2xl lg:text-2xl font-regular text-primary-darker">Fact #2</h2>
            <p className="text-grey-900">
            Preparing your home for sale is often more important than pricing it right. Start by fixing, painting, and cleaning it.
            </p>
          </div>
          <div className="pt-10 px-10 sm:-mx-3">
            <h2 className="text-2xl lg:text-2xl font-regular text-primary-darker">Fact #3</h2>
            <p className="text-grey-900">
            Want top dollar? You need an agent with a marketing plan.
            </p>
          </div>
          <div className="pt-10 px-10 sm:-mx-3">
            <h2 className="text-2xl lg:text-2xl font-regular text-primary-darker">Fact #4</h2>
            <p className="text-grey-900">
            Plan ahead – The average time to preparing, market, and sell the house is 45 days. Additionally, Escrow takes 45 days.
            </p>
          </div>
          <div className="pt-10 px-10 sm:-mx-3">
          <h2 className="text-2xl lg:text-2xl font-regular text-primary-darker">Fact #5</h2>
            <p className="text-grey-900">
            There are 20 ways a buyer can back out of an escrow – Work with an agent that controls that
            </p>
          </div>
          <div className="pt-10 px-10 sm:-mx-3">
          <h2 className="text-2xl lg:text-2xl font-regular text-primary-darker">Fact #6</h2>
            <p className="text-grey-900">
            Buyer financing is more important than the offer price – Make sure you and your agent double check financing
            </p>
          </div>
          <div className="pt-10 px-10 sm:-mx-3">
          <h2 className="text-2xl lg:text-2xl font-regular text-primary-darker">Fact #7</h2>
            <p className="text-grey-900">
            80% of value is square footage of home, 10% amenities, 10% condition & neighborhood
            </p>
          </div>
          <div className="pt-10 px-10 sm:-mx-3">
          <h2 className="text-2xl lg:text-2xl font-regular text-primary-darker">Fact #8</h2>
            <p className="text-grey-900">
            Be Flexible. Sellers that are flexible about price, showings and timetable WIN!
            </p>
          </div>
          <div className="pt-10 px-10 sm:-mx-3">
          <h2 className="text-2xl lg:text-2xl font-regular text-primary-darker">Fact #9</h2>
            <p className="text-grey-900">
            If you are not in a property within 90 days — either you or your agent are not serious about buying a home
            </p>
          </div>
        </div>
      </section>
  
      <section className="container mx-auto my-20 py-24 bg-gray-200 rounded-lg text-center">
        <h3 className="text-5xl font-regular">Have You Priced Your Home?</h3>
        <p className="mt-8 mb-5 mx-5 text-xl font-light">
          We are available 24/7 to answer any questions about selling your home, call us or feel free to send us a message
          through our chat.
        </p>
        <a href="tel:562-972-1390">
          <Button size="l">
            Call Now
          </Button>
        </a>
      </section>
    </Layout>
  );

export default Seller;